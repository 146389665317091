import { useState, useEffect, useCallback } from 'react';

const MAX_GUESSES = 6;

export function useGameLogic(initialSolution = '') {
    const [solution, setSolution] = useState(initialSolution);
    const [definition, setDefinition] = useState('');
    const [guesses, setGuesses] = useState(Array(MAX_GUESSES).fill(''));
    const [currentGuess, setCurrentGuess] = useState('');
    const [gameOver, setGameOver] = useState(false);
    const [keyboardState, setKeyboardState] = useState({});
    const [hintedLetters, setHintedLetters] = useState(new Set());
    const [greenHints, setGreenHints] = useState({});

    useEffect(() => {
        fetch('../urban_dictionary_words.json')
            .then(response => response.json())
            .then(data => {
                const todayWord = data[data.length - 1];
                setSolution(todayWord.word);
                setDefinition(todayWord.definition);
            });
    }, []);

    const updateGameState = useCallback((newGuess) => {
        const solutionArray = [...solution.replace(/ /g, '')];
        const guessArray = [...newGuess];
        const newKeyboardState = { ...keyboardState };
        const newGreenHints = { ...greenHints };

        // Greens
        guessArray.forEach((letter, index) => {
            if (letter === solutionArray[index]) {
                newKeyboardState[letter] = 'key-correct';
                newGreenHints[index] = letter;
            }
        });

        guessArray.forEach((letter, index) => {
            // If not green and still in solution, yellow
            if (letter !== solutionArray[index]) {
                if (solutionArray.includes(letter) && newKeyboardState[letter] !== 'key-correct') {
                    newKeyboardState[letter] = 'key-present';
                } else if (!solutionArray.includes(letter)) {
                    newKeyboardState[letter] = 'key-absent';
                }
            }
        });

        setKeyboardState(newKeyboardState);
        setGreenHints(newGreenHints);
    }, [solution, keyboardState, greenHints]);

    const handleKeyPress = useCallback((key) => {
        if (gameOver) return;

        if (key === 'enter') {
            if (currentGuess.length !== solution.replace(/ /g, '').length) return;

            setGuesses(prevGuesses => {
                const newGuesses = [...prevGuesses];
                const currentGuessIndex = newGuesses.findIndex(guess => guess === '');
                if (currentGuessIndex !== -1) {
                    newGuesses[currentGuessIndex] = currentGuess;
                    updateGameState(currentGuess);

                    if (currentGuess === solution.replace(/ /g, '')) {
                        setGameOver(true);
                    } else if (currentGuessIndex === MAX_GUESSES - 1) {
                        setGameOver(true);
                    }
                }
                return newGuesses;
            });

            setCurrentGuess('');
        } else if (key === 'backspace') {
            setCurrentGuess(prevGuess => prevGuess.slice(0, -1));
        } else if (currentGuess.length < solution.replace(/ /g, '').length) {
            setCurrentGuess(prevGuess => prevGuess + key);
        }
    }, [currentGuess, gameOver, solution, updateGameState]);

    const getNextHint = useCallback(() => {
        const solutionArray = [...solution.replace(/ /g, '')];

        // First, try to find a yellow hint
        for (let i = 0; i < solutionArray.length; i++) {
            const letter = solutionArray[i];
            if (!greenHints[i] && !hintedLetters.has(letter) && (!keyboardState[letter] || keyboardState[letter] === 'key-absent')) {
                return { letter, type: 'yellow', position: i };
            }
        }

        // If all yellow hints are given, move to green hints
        for (let i = 0; i < solutionArray.length; i++) {
            if (!greenHints[i]) {
                return { letter: solutionArray[i], type: 'green', position: i };
            }
        }

        return null;
    }, [solution, hintedLetters, keyboardState, greenHints]);

    const handleHint = useCallback(() => {
        const hint = getNextHint();

        if (hint) {
            const newKeyboardState = { ...keyboardState };
            const newHintedLetters = new Set(hintedLetters);
            const newGreenHints = { ...greenHints };

            if (hint.type === 'yellow') {
                newKeyboardState[hint.letter] = 'key-present';
                newHintedLetters.add(hint.letter);
            } else if (hint.type === 'green') {
                newKeyboardState[hint.letter] = 'key-correct';
                newGreenHints[hint.position] = hint.letter;
            }

            setKeyboardState(newKeyboardState);
            setHintedLetters(newHintedLetters);
            setGreenHints(newGreenHints);

            // Apply all consecutive green hints to the current guess
            const newGuess = [...currentGuess.padEnd(solution.replace(/ /g, '').length, ' ')];
            let prevPosition = -1;
            const newestHintPosition = hint.type === 'green' ? hint.position : -1;
            Object.entries(newGreenHints)
                .sort(([a], [b]) => Number(a) - Number(b))
                .forEach(([position, letter]) => {
                    position = Number(position);
                    if (position === prevPosition + 1 || prevPosition === -1) {
                        newGuess[position] = letter;
                        prevPosition = position;
                        if (position === newestHintPosition) {
                            return false;
                        }
                    } else {
                        return false;
                    }
                });

            setCurrentGuess(newGuess.join('').trim());
        }
    }, [getNextHint, currentGuess, hintedLetters, keyboardState, solution, greenHints]);

    const generateShareText = useCallback(() => {
        let shareText = `Slangle ${guesses.filter(guess => guess !== '').length}/${MAX_GUESSES}\n\n`;
        const solutionNoSpaces = solution.replace(/ /g, '');

        guesses.forEach((guess, index) => {
            if (guess !== '') {
                let solutionIndex = 0;
                guess.split('').forEach((letter, letterIndex) => {
                    while (solution[solutionIndex] === ' ') {
                        shareText += ' ';  // Add a space in the share text
                        solutionIndex++;
                    }
                    if (letter === solution[solutionIndex]) {
                        shareText += '🟩';
                    } else if (solutionNoSpaces.includes(letter)) {
                        shareText += '🟨';
                    } else {
                        shareText += '⬛';
                    }
                    solutionIndex++;
                });
                // Check if there are any trailing spaces in the solution
                while (solutionIndex < solution.length && solution[solutionIndex] === ' ') {
                    shareText += ' ';
                    solutionIndex++;
                }
                shareText += '\n';
            }
        });

        return shareText;
    }, [guesses, solution]);

    const shareResults = useCallback(() => {
        const shareText = generateShareText();

        if (navigator.share) {
            navigator.share({
                title: 'My Slangle Results',
                text: shareText,
            }).then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
        } else {
            // Fallback for browsers that don't support navigator.share
            navigator.clipboard.writeText(shareText).then(() => {
                alert('Results copied to clipboard!');
            }).catch(err => {
                console.error('Failed to copy: ', err);
            });
        }
    }, [generateShareText]);

    return {
        solution,
        definition,
        guesses,
        currentGuess,
        gameOver,
        keyboardState,
        greenHints,
        handleKeyPress,
        handleHint,
        generateShareText,
        shareResults,
    };
}