import React, { useState, useEffect, useCallback } from 'react';
import './App.css';
import DarkModeToggle from './components/DarkModeToggle';
import VirtualKeyboard from './components/VirtualKeyboard';
import HintModal from './components/HintModal';
import { GameBoard } from './components/GameBoard';
import { useGameLogic } from './hooks/useGameLogic';

export default function App() {
  const {
    solution,
    definition,
    guesses,
    currentGuess,
    gameOver,
    keyboardState,
    greenHints,
    handleKeyPress,
    handleHint,
    shareResults
  } = useGameLogic();

  const [showHintModal, setShowHintModal] = useState(false);

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Enter') {
      handleKeyPress('enter');
    } else if (event.key === 'Backspace') {
      handleKeyPress('backspace');
    } else if (event.key.match(/^[a-z]$/i)) {
      handleKeyPress(event.key.toLowerCase());
    }
  }, [handleKeyPress]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const handleHintRequest = () => {
    setShowHintModal(true);
  };

  const handleHintConfirm = () => {
    setShowHintModal(false);
    handleHint();
  };

  return (
    <div className="App">
      <header>
        <h1>Slangle</h1>
        <button className="hint-button" onClick={handleHintRequest}>Hint</button>
        <HintModal
          isOpen={showHintModal}
          onClose={() => setShowHintModal(false)}
          onConfirm={handleHintConfirm}
        />
        <DarkModeToggle />
      </header>
      <GameBoard
        solution={solution}
        guesses={guesses}
        currentGuess={currentGuess}
        greenHints={greenHints}
      />
      {gameOver && (
        <div className="game-over">
          <p>Game Over! The phrase was "{solution}"</p>
          <p className="definition">Definition: {definition}</p>
          <button onClick={shareResults}>Share Results</button>
        </div>
      )}
      <VirtualKeyboard
        onKeyPress={handleKeyPress}
        keyboardState={keyboardState}
      />
    </div>
  );
}