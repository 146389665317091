import React from 'react';

function getBoxColor(guess, index, solution) {
    if (guess === '') return '';

    const solutionArray = [...solution];
    const guessArray = [...guess];
    
    const letterCounts = {};
    solutionArray.forEach(letter => {
        letterCounts[letter] = (letterCounts[letter] || 0) + 1;
    });

    // First pass: Mark green letters and decrease counts
    const colors = guessArray.map((letter, i) => {
        if (letter === solutionArray[i]) {
            letterCounts[letter]--;
            return 'green';
        }
        return null;
    });

    // Second pass: Mark yellow or grey letters
    guessArray.forEach((letter, i) => {
        if (colors[i] === null) {
            if (letterCounts[letter] > 0) {
                letterCounts[letter]--;
                colors[i] = 'yellow';
            } else {
                colors[i] = 'grey';
            }
        }
    });

    return colors[index];
}

export function GameBoard({ solution, guesses, currentGuess, greenHints }) {
    return (
        <div className="game-board">
            {guesses.map((guess, i) => (
                <div key={i} className="guess-row">
                    {solution.split('').map((char, j) => (
                        char === ' ' ? (
                            <div key={j} className="guess-box space"></div>
                        ) : (
                            <div
                                key={j}
                                className={`guess-box 
                  ${getBoxColor(guess, j - solution.slice(0, j).split(' ').length + 1, solution.replace(/ /g, ''))} 
                  ${guess === '' && i === guesses.findIndex(g => g === '') ? 'current' : ''}
                  ${greenHints[j - solution.slice(0, j).split(' ').length + 1] ? 'locked' : ''}`}
                            >
                                {guess !== '' ? guess[j - solution.slice(0, j).split(' ').length + 1] : (i === guesses.findIndex(g => g === '') ? currentGuess[j - solution.slice(0, j).split(' ').length + 1] : '')}
                            </div>
                        )
                    ))}
                </div>
            ))}
        </div>
    );
}